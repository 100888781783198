<template>
    <div class="container custom-py d-flex flex-column align-items-center justify-content-center" style="max-height: 100vh;">
        <div class="w-75">
            <div class="row">
                <!-- 註冊按鈕 -->
                <div class="col-6">
                    <button class="btn btn-primary w-100 btn-custom btn-register" @click="handleRegist">
                        <!-- 圖片置中 -->
                        <img src="https://cdn-icons-png.flaticon.com/512/3456/3456388.png" alt="Register" class="btn-icon">
                        <!-- 文字另起一行 -->
                        <div>註冊</div>
                    </button>
                </div>

                <!-- 解說按鈕 -->
                <div class="col-6">
                    <button class="btn btn-secondary w-100 btn-custom btn-consult" @click="handleConsult">
                        <img src="https://cdn-icons-png.flaticon.com/512/1469/1469975.png" alt="Consult" class="btn-icon">
                        諮詢
                    </button>
                </div>
            </div>

            <!-- 設定按鈕 -->
            <div class="row mt-3">
                <div class="col-12">
                    <button class="btn btn-success w-100 btn-custom btn-about" @click="handleAbout">
                        <img src="https://cdn-icons-png.flaticon.com/512/8819/8819767.png " alt="About" class="btn-icon">
                        設定
                    </button>
                </div>
            </div>
            <!-- 登出按鈕 -->
            <div class="row mt-3">
                <div class="col-12">
                    <button class="btn btn-success w-100 btn-custom btn-logout" @click="handleLogout">
                        <img src="https://cdn-icons-png.flaticon.com/512/10754/10754511.png " alt="Logout" class="btn-icon">
                        離開
                    </button>
                </div>
            </div>
        </div>
    </div>
    <CopyrightFooterVue />
</template>
  
<script setup>
import { onMounted } from 'vue';
import CopyrightFooterVue from "./components/CopyrightFooter.vue";
import { useRouter } from 'vue-router';

import log from '@/lib/logger/index.js';
const router = useRouter();
/**
 * 處理註冊動作
 */
const handleRegist = () => {
    router.push('/register')
};
/**
 * 處理諮詢動作
 */
 const handleConsult = () => {
    router.push('/consult')
};
/**
 * 處理設定動作
 */
const handleAbout = () => {
    router.push('/about')
};
/**
 * 處理登入動作
 */
const handleLogout = () => {
    router.push('/')
};

onMounted(() => {
    // 如果你正在使用 vue-logger-plugin 或其他日誌記錄工具，
    log.info('Component setup...');
});


</script>
  
<style>
   
    /* 按鈕樣式 */
    .btn-custom {
        font-size: 2.5rem;
        border: none;
        color: white;
        font-weight: bold;
        background-color: var(--mint-blue); /* 使用主色調背景 */
        box-shadow: 4px 4px #669BBC; /* 右側和底部陰影 */
        transition: all 0.3s ease; /* 平滑過渡效果 */
        cursor: pointer;
        padding: 10px 20px; /* 增加適當的填充 */
        border-radius: 5px; /* 圓角設計 */
    }

    .btn-custom:hover {
        background-color: var(--dark-mint); /* 滑鼠懸停時更深的背景色 */
    }

    .btn-custom:active {
        box-shadow: 2px 2px #669BBC; /* 點擊時陰影更短 */
        transform: translateY(2px) translateX(2px); /* 輕微下移和右移按鈕 */
        background-color: var(--light-mint); /* 按下時更淺的背景色 */
    }


    /* 主色調 - 薄荷藍 */
    :root {
        --mint-blue: #5B9EAD; /* 薄荷藍 */
        --dark-mint: #407880; /* 深薄荷藍 */
        --light-mint: #8FC1CC; /* 淺薄荷藍 */
        --accent-color: #FF6B6B; /* 強調色（珊瑚色） */
        --text-color: #333; /* 文字顏色 */
        --background-color: #F0F0F0; /* 背景色 */
    }

    .btn-register {
        background-color: var(--mint-blue);
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: none;
    }

    .btn-consult {
        background-color: var(--light-mint);
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: none;
    }

    .btn-about {
        background-color: var(--dark-mint);
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: none;
    }

    .btn-logout {
        background-color: var(--accent-color); /* 使用強調色 */
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: none;
    }


    .btn-icon {
        width: 60px;
        /* 設置圖標大小 */
        height: auto;
        margin-bottom: 5px;
        margin-top: 5px;
        /* 圖標和文字之間的間隙 */
    }

    .custom-py{
        padding-top: 4.5rem!important;
        padding-bottom: 3rem!important;
    }
</style>
  