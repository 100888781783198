// 用於創建日誌實例的文件

/**
 * 用於創建日誌實例的文件
 * @module logger
 */

import { createLogger } from 'vue-logger-plugin';

/**
 * 日誌配置選項
 * @typedef {Object} LoggerOptions
 * @property {Function} prefixFormat - 用於定義日誌前綴格式的函數
 */

/**
 * 創建日誌實例
 * @param {LoggerOptions} options - 日誌配置選項
 * @returns {Object} - 日誌實例
 */
const loggerOptions = {
    prefixFormat: ({ level, caller }) => {
        const timestamp = new Date().toISOString();
        return caller
            ? `[${timestamp}] [${level.toUpperCase()}] [${caller?.fileName}:${caller?.functionName}:${caller?.lineNumber}]`
            : `[${timestamp}] [${level.toUpperCase()}]`;
    },
};

const log = createLogger(loggerOptions);

export default log;
