import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import RegistView from '../views/QRCodeGenerate.vue'
// import LoginView from '@/views/LoginView.vue'
import RegisterView from '@/views/RegisterView.vue'
import MenuView from '@/views/MenuView.vue'
import ConsultView from '@/views/ConsultView.vue'
const routes = [
  {
    path: '/register',
    name: 'register',
    component: RegistView
  },
  {
    path: '/',
    name: 'register',
    component: RegisterView
  },
  {
    path: '/menu',
    name: 'menu',
    component: MenuView
  },
  {
    path: '/consult',
    name: 'consult',
    component: ConsultView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/generate',
    name: 'Generate QR Code',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/QRCodeGenerate.vue')
  }
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: process.env.IS_ELECTRON ? createWebHashHistory() : createWebHistory(),
  routes
})

export default router
