import { createApp } from 'vue'
import App from './App.vue'
import i18n from './plugins/i18n' // 引入

import 'bootstrap/dist/css/bootstrap.min.css'
import "bootstrap"
import router from './router'
import VueTheMask from 'vue-the-mask'

createApp(App).use(router).use(i18n).use(VueTheMask).mount('#app')
