<template>
  <div class="settings">
    <div class="header">
      <button class="back-button" @click="handleBack">＜ Back</button>
      <h2 class="settings-title"></h2>
    </div>
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">報告管理</h5>
        <form @submit.prevent="checkPhoneNumber">
          <div class="row">
            <div class="col-md-2">
              <label for="phoneNumber" class="form-label">手機門號</label>
            </div>
            <div class="col-md-10">
              <input type="text" class="form-control" id="phoneNumber" v-model="phoneNumber" placeholder="輸入手機門號">
            </div>
          </div>
          <div class="row mt-3">
            <div class="col text-center">
              <button type="submit" class="btn btn-primary">檢索</button>
            </div>
          </div>
        </form>
        <div class="row mb-3">
            <languageComp />
        </div>
      </div>
    </div>
  </div>
  <!-- 加入版權宣告 footer component -->
  <CopyrightFooter/>
</template>

<script setup>
import { ref } from 'vue';
import CopyrightFooter from './components/CopyrightFooter'
const phoneNumber = ref('');

/**
 * 檢查手機號碼是否有效
 */
function checkPhoneNumber() {
  const validPhoneNumber = /^[0-9]{10}$/;
  if (validPhoneNumber.test(phoneNumber.value)) {
    alert("手機號碼有效！");
  } else {
    alert("請輸入有效的手機號碼。");
  }
}
import { useRouter } from 'vue-router';
const router = useRouter();

/**
 * 處理返回動作
 */
 const handleBack = () => {
    router.push('/menu')
};
</script>


<style scoped>
  .card-title {
    font-size: 2.5rem; /* 例如：20px / 16px = 1.25rem */
  }

  .card-subtitle {
    font-size: 2rem; /* 例如：16px / 16px = 1rem */
  }

  .form-control {
    font-size: 1.5rem; /* 例如：14px / 16px = 0.875rem */
  }

  label {
    font-size: 1.5rem; /* 例如：16px / 16px = 1rem */
  }

  .btn {
    font-size: 1.3rem; /* 例如：14px / 16px = 0.875rem */
  }

  h5 {
    font-size: 1.25rem; /* 例如：20px / 16px = 1.25rem */
  }

  h6 {
    font-size: 1rem; /* 例如：16px / 16px = 1rem */
  }

  small {
    font-size: 1rem; /* 例如：12px / 16px = 0.75rem */
  }

  p {
    font-size: 1rem; /* 例如：16px / 16px = 1rem */
  }

  .settings-title {
    font-size: 2.4rem;
    color: #333;
    margin: 0;
    flex-grow: 1;
    text-align: center;
  }

  .settings {
    margin: 2rem;
    padding: 1rem;
    background-color: #f5f5f5;
    border-radius: 0.8rem;
    box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.1);
  }

  .header {
    display: flex;
    align-items: center;
    padding: 1rem 0;
  }
  .back-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.6rem;
    margin-right: 1rem;
  }
  .card {
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 5px;
  }

  .card-title {
    text-align: center;
    font-weight: bold;
  }
  .card-subtitle {
    text-align: center;
    font-weight: bold;
  }
  .tight-spacing {
    margin-bottom: 0.2rem;
  }

</style>