// services/apiService.js
import axios from 'axios';

export const apiClient = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json'
  }
});

export const apiBrotilClient = axios.create({
    baseURL: '/brotli',
    headers: {
      'Content-Type': 'application/json'
    }
  });

export const addCustomer = async (customerData) => {
  let data = {
    "patient_name": customerData.name,
    "sex": customerData.gender,
    "birthday": customerData.birthday.replaceAll('-', '/'),
    "cellphone": customerData.cellphone,
    "height": customerData.height,
    "weight": customerData.weight

  }
  const response = await apiClient.post('/addPatient', JSON.stringify(data));
  return response.data;
}

export const searchCustomerID = async (cellphone) => {
  const response = await apiClient.post('/SearchPatient', JSON.stringify({ cellphone }));
  return response.data;
}

export const compressData = async (data) => {
  const response = await apiBrotilClient.post('/compress', JSON.stringify({ input_string: JSON.stringify(data) }));
  return response.data;
}
