<template>
  <div id="app">
    <!-- 隱藏導航列的條件式 -->
    <navbar v-if="showNavbar" />

    <!-- 主要內容 -->
    <router-view />
  </div>
</template>

<script>
  export default {
    data() {
      return {
        showNavbar: true, // 控制導航列的顯示與隱藏
      };
    },
    created() {
      this.$router.beforeEach((to, from, next) => {
        if (to.path === '/') {
          // 在根路由時隱藏導航列
          this.showNavbar = false;
        } else {
          // 在其他路由時顯示導航列
          this.showNavbar = true;
        }
        next();
      });
    },
  };
</script>






<style>

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
