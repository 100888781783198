<template>
  <div class="container">
    <div class="row mb-3 d-flex justify-content-center">
      <img class="px-5 " style="max-height: 10vh; width:auto;" src="../assets/QuantumLogo.png">
    </div>
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">{{ $t('biological_radar_fluctuation_detector') }}</h5>
        <h6 class="card-subtitle mb-2 text-muted">{{ $t('user_information_registration') }}</h6> 
          <div class="row mb-3">
            <languageComp />
          </div>
          <div class="row mb-3">
            <div class="col">
              <label for="name">{{ $t('name_suggestion') }}</label>
              <input v-model="name" type="text" class="form-control" id="name" :placeholder="$t('enter_name')" maxlength="20" required>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col">
              <label for="phone">{{ $t('phone') }}*</label>
              <input v-model="phone" type="tel" class="form-control" id="phone" :placeholder="$t('enter_phone_number')" required>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col">
              <label>{{ $t('gender') }}*</label>
              <div>
                <label>
                  <input v-model="computedGender" type="radio" value="男性"> {{ $t('male') }}
                </label>
              </div>
              <div>
                <label>
                  <input v-model="computedGender" type="radio" value="女性"> {{ $t('female') }}
                </label>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col">
              <label for="birthdate">{{ $t('birthdate') }}*</label>
              <input v-model="birthdate" v-mask="'####-##-##'" type="text" class="form-control" :max="minBirthdate" placeholder="YYYYMMDD" required>
              <small style="color: red; font-weight: bold;">{{ $t('age_recommendation') }}</small>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-6">
              <label for="height">{{ $t('height') }}* cm</label>
              <input v-model="height" type="number" class="form-control" id="height" min="150" max="200" required>
            </div>
            <div class="col-6">
              <label for="weight">{{ $t('weight') }}* kg</label>
              <input v-model="weight" type="number" class="form-control" id="weight" min="30" max="120"  required>
            </div>
          </div>
          <div class="row mb-3">
            <button @click="submitForm" class="btn btn-primary btn-block">{{ $t('generate_qr_code') }}</button>
          </div>
          <div v-if="showQRCode" class="row mb-3">
            <div class="card mb-3 p-3" id="memberCard">
              <h1 class="card-title">{{ $t('biological_radar_fluctuation_detector') }}</h1>

              <div class="row g-0 d-flex align-items-center">
                <div class="col">
                  <div class="card-body">
                    <div class="mt-3 d-flex justify-content-center align-items-center">
                      <QRCodeVue3 
                        :value="qrCode" 
                        :key="qrCode" 
                        :height="450"
                        :width="450"
                        :qr-options="{ errorCorrectionLevel: 'H' }" 
                        :image-options="{ hideBackgroundDots: true, imageSize: 0.2, margin: 10 }" 
                        :image="logoImg"
                        imgclass="w-100"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <p class="text-center tight-spacing" style="font-size: 30px">{{ $t('name') }}: {{ name }}</p>
              <p class="text-center tight-spacing" style="font-size: 30px">{{ $t('gender') }}: {{ computedGender }}</p>
              <p class="text-center tight-spacing" style="font-size: 30px">{{ $t('birthdate') }}: {{ birthdate }}</p>
            </div>
            <button @click="downloadCardAsJPG" class="btn btn-primary btn-block">{{ $t('download qr code') }}</button>
          </div>
      </div>
    </div>
  </div>
  <!-- 加入版權宣告 footer component -->
  <CopyrightFooter/>
</template>

<script setup>
import { computed, ref } from 'vue'
import QRCodeVue3 from "qrcode-vue3";
import CopyrightFooter from './components/CopyrightFooter'
const logoImg = require('../assets/quantum_logo.svg')
import html2canvas from 'html2canvas';

import languageComp from '@/components/languageComp.vue';

const name = ref("")
const phone = ref("")
const gender = ref("")
const height = ref("")
const weight = ref("")

// 帮助函数，计算从现在起特定年数之前的日期
function getDateYearsAgo(yearsAgo) {
  const date = new Date();
  date.setFullYear(date.getFullYear() - yearsAgo);
  return date.toISOString().split('T')[0];  // 返回格式为 YYYY-MM-DD 的日期字符串
}

// 使用计算属性来管理日期
const birthdate = ref(getDateYearsAgo(18));  // 初始化为 18 年前的日期

const qrCode = ref("");  // 管理 QR Code 的数据
const showQRCode = ref(false);  // 管理显示 QR Code 的状态

// 计算属性来获取最小生日日期，用于输入限制等
const minBirthdate = computed(() => getDateYearsAgo(18));

const submitForm = (async () => {
  if (name.value === '' || gender.value === '' || birthdate.value === '') {
    alert('請填寫必填欄位');
    return;
  }

  // 檢查年齡是否達到十八歲
  const today = new Date();
  const age = today.getFullYear() - new Date(birthdate.value).getFullYear();

  if (age < 18) {
    alert('您的年齡必須達到18歲以上');
    return;
  }

  qrCode.value = ''; // 清空之前的 QR Code
  showQRCode.value = false;
  const id = await searchCustomerID(name.value, gender.value == 'male' ? '1' : '0', birthdate.value, phone.value, height.value, weight.value)
  if (id != false) {
    let data = {
      name: name.value,
      gender: gender.value,
      birthdate: birthdate.value,
      phone: phone.value,
      id: id
    };
    let encodeText = await compressData(data)
    // 生成 QR Code
    if (data.id != undefined && data.id != false) {
      qrCode.value = encodeText.compressed_string
      showQRCode.value = true
    }
    else
      alert("条码生成失败")
  }
  else
    alert("条码生成失败")
    
})

import { apiClient, addCustomer, compressData } from '../services/apiService';

const searchCustomerID = async (name, gender, birthday, cellphone, weight, height) => {
  try {
    const response = await apiClient.post('/SearchPatient', JSON.stringify({ cellphone }));

    const result = response.data.length > 0
      ? response.data.find(element => element[6] === cellphone)
      : null;

    if (result) {
      birthdate.value = result[4].replaceAll('/', '-');
      return result[1];
    } else {
      let registerNewID = await addCustomer({ name, gender, birthday, cellphone, weight, height });
      // if registerNewID is Integer, it means the customer is registered successfully
      registerNewID = registerNewID.Ind
      if (Number.isInteger(registerNewID)) {
        return registerNewID;
      } else {
        alert('注册失败，请联系网站管理员。');
        return false;
      }
    }
  } catch (error) {
    console.error('Error searching for customer ID:', error);
    throw error; // Throw error to handle it on the calling side
  }
}

/**
 * 創建一個 computed 屬性 computedGender，對 gender 進行轉換
 * @type {import('vue').ComputedRef<string>}
 */
 const computedGender = computed({
  get: () => {
    return gender.value === 'male' ? '男性' : gender.value === 'female' ? '女性' : '';
  },
  set: (newValue) => {
    gender.value = newValue === '男性' ? 'male' : newValue === '女性' ? 'female' : '';
  }
});
/**
 * 將卡片轉換為JPG並下載
 * @async
 */
 const downloadCardAsJPG = async () => {
  const cardElement = document.querySelector('#memberCard'); // 選擇你想要轉換的div
  if (!cardElement) return;
  // 找到卡片裡的所有圖像元素
  const imgElements = cardElement.querySelectorAll('img');
  
  // 手動加載每一個圖像，並確保它們都加載完成
  const loadPromises = Array.from(imgElements).map(imgElement => {
    console.log(imgElement)
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = resolve;
      img.src = imgElement.src;
    });
  });

  // 等待所有圖像加載完成
  await Promise.all(loadPromises);
  
  const canvas = await html2canvas(cardElement);
  const imgData = canvas.toDataURL('image/jpeg');
  const link = document.createElement('a');
  
  link.href = imgData;
  link.download = 'card.jpg';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

</script>


<style scoped>
  .container{
    padding: 20px;
  }
  .card {
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 5px;
  }

  .card-title {
    text-align: center;
    font-weight: bold;
  }
  .card-subtitle {
    text-align: center;
    font-weight: bold;
  }
  .tight-spacing {
    margin-bottom: 0.2rem;
  }

  .btn-icon{
    width: 50px;
  }
</style>